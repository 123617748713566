import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private _httpClient: HttpClient) { }

  baseUrl = environment.igpsBaseUrl.apiUrl;

  getEmailDetails(emailId: string) {
    return this._httpClient.get(`${this.baseUrl}` + `/emailservice/dashboard/v1/emaildetail/` + `${emailId}`)
  }

  getEmailAttachment(emailAttachmentId: string) {
    return this._httpClient.get(`${this.baseUrl}` + `/emailservice/dashboard/v1/emailattachmenturl/` + `${emailAttachmentId}`)
  }
}
