// @ts-ignore
import { HttpRequest, HttpHeaders, HttpHandler, HttpEvent, HttpInterceptor as Interceptor } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { ErrorHandler, Injectable } from '@angular/core';
import { catchError, from, lastValueFrom, Observable, throwError } from 'rxjs';
import { getToken } from '@igps/client-root-config';
import { Router } from '@angular/router';


@Injectable()
export class HttpInterceptor {
  constructor(private router: Router) {
  }
  correlationId = uuidv4();

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const token = await getToken();
    const headers = new HttpHeaders({
      'Cache-control': 'no-cache',
      Expires: '0',
      Pragma: 'no-cache',
      originapp: 'client',
      'Correlation-Id': this.correlationId,
      Authorization: `Bearer ${token}`,
    });

    if (req.body !== FormData) {
      headers.set('Content-Type', 'application/json');
    }
    const authReq = req.clone({
      headers,
    });
    return lastValueFrom(next.handle(req.clone(authReq)));
  }
}

