import { NgModule } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchEmailComponent } from '../app/components/search-email/search-email.component';
import { HttpInterceptor } from '../app/global/interceptor/header-interceptor';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { IconModule } from '@ui-core/icon';
import { ToastModule } from '@ui-core/toast';
import { SpinnerModule } from '@igps/spinner';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafeHtmlPipe } from './components/search-email/safepipe';
import { PipeModule, ConvertTimePipe } from '@igps/pipe';
@NgModule({
  declarations: [
    AppComponent,
    SearchEmailComponent,
    SafeHtmlPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    IconModule,
    ToastModule,
    SpinnerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipeModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptor,
    multi: true,
  },
  {
    provide: ErrorHandler,
    useClass: HttpInterceptor
  },
    ConvertTimePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
