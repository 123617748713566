import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { environment } from 'src/environments/environment';
import { SearchEmailComponent } from '../app/components/search-email/search-email.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

const routes: Routes = [
  // {
  //   path: environment.appPath,
  //   children: [
  //     {
  //       path: '',
  //       component:SearchEmailComponent 
  //     }
  //   ]
  // },
  { path: '', component: AppComponent },
  { path: '**', component: EmptyRouteComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), MatSelectModule,
    MatFormFieldModule],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
})
export class AppRoutingModule { }
