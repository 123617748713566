import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { onChangeProcess, GetSelectedProcess } from '@igps/top-utility';
import { Subscription, finalize } from 'rxjs';
import { EmailService } from 'src/app/services/email.service';
import { UtilityService } from 'src/app/services/utility.service';
import { getUserInfo } from '@igps/client-root-config';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentExtensionEnum } from 'src/app/models/documentExtension';
import { singleSpaPropsSubject } from 'src/single-spa/single-spa-props';
import { getTimezone, timezone$ } from '@igps/client-root-config'
@Component({
  selector: 'app-search-email',
  templateUrl: './search-email.component.html',
  styleUrls: ['./search-email.component.scss']
})
export class SearchEmailComponent implements OnInit, OnDestroy {
  selectedProductionProcessId: any;
  selectedProductionProcessName: any;
  emailAttachment: any;
  emailDetails: any;
  fileNumber!: number;
  emailDetailSubscription$!: Subscription;
  onChangeProcessSubscription!: Subscription;
  onSelectedTAskSubscription!: Subscription;
  emailId!: string | null;
  taskSource!: number;
  fromEmail!: string;
  toEmail!: string;
  subjectEmail!: string;
  contentEmail!: string;
  showSpinner: boolean = false;
  userInfo!: any;
  selectedProcess!: { id: string; name: string; taskSource: number; };
  searchEmailID!: string;
  receivedDate!: any;
  isFileExist: boolean = false;
  timezone: any;
  constructor(private _emailService: EmailService, private utilityService: UtilityService, private cdref: ChangeDetectorRef, private router: Router, private changeDetectorRef: ChangeDetectorRef, private route: ActivatedRoute) {

  }
  ngOnInit(): void {
    singleSpaPropsSubject.subscribe((props: any) => {
      this.searchEmailID = props?.emailId;
    });
    const keyId = 'emailId';
    if (this.searchEmailID !== null) {
      this.getEmailDetailsRes(this.searchEmailID);
    }
    this.timezone = getTimezone();
    this.userInfo = getUserInfo();
  }
  ngOnDestroy(): void {
    // this.onChangeProcessIdSubscription.unsubscribe();
    this.emailDetailSubscription$?.unsubscribe();
    this.onChangeProcessSubscription?.unsubscribe();
  }

  getEmailDetailsRes(emailId: string) {
    // if (!isBackground) {
    //   this.utilityService.setSpinner(true);
    // }
    this.utilityService.setSpinner(true);
    this.emailDetailSubscription$ = this._emailService.getEmailDetails(emailId).pipe(
      finalize(() => {
        this.utilityService.setSpinner(false);
      })
    ).subscribe({
      next: (result) => {
        this.emailDetails = result;
        this.fileNumber = this.emailDetails?.fileNumber;
        if (this.fileNumber) {
          this.isFileExist = true;
        }
        else {
          this.isFileExist = false;
        }
        this.fromEmail = this.emailDetails?.from;
        this.toEmail = this.emailDetails?.to;
        this.subjectEmail = this.emailDetails?.subject;
        this.contentEmail = this.emailDetails?.body;
        this.receivedDate = this.emailDetails?.receivedDate;
      },
      error: (error: HttpErrorResponse) => {
        this.utilityService.openToast(error?.error.Detail, true);
      }
    });
  }


  previewAttachment(attachment: any) {
    this.utilityService.setSpinner(true);
    this._emailService.getEmailAttachment(attachment.id).pipe(
      finalize(() => {
        this.utilityService.setSpinner(false);
      })
    ).subscribe((res: any) => {
      // if (res && res.bytes) {
      if (res) {
        if (attachment.contentType == 'application/pdf') {
          // var blob = this.b64toBlob(res.bytes, "application/pdf");
          const a = document.createElement("a");
          document.body.appendChild(a);
          const url = res.url;
          window.open(url, '_blank')

        }
        else {
          if (attachment.contentType == 'image/tiff' || attachment.contentType == 'image/jpeg' || attachment.contentType == 'image/png') {
            this.downloadImage(res.url, attachment.name);
          } else {
            const a = document.createElement("a");
            a.href = res.url;
            a.download = attachment.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }
      }
    })
  }
  async downloadImage(imageSrc: any, nameOfDownload: any) {
    const response = await fetch(imageSrc);

    const blobImage = await response.blob();

    const href = URL.createObjectURL(blobImage);

    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    anchorElement.download = nameOfDownload;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  }
  formatBytes(bytes: any, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }
  onBack() {
    // this.timerSubscription = timer(500).subscribe(() => this.navigate());
    // NavigateBackEventStart.emit();
    this.navigate();
  }

  navigate() {
    if (this.userInfo.assumedRoleId == 1) {
      this.router.navigate(['manager/dashboard']);
    }
    else {
      this.router.navigate(['processor/dashboard']);
    }
  }
}
